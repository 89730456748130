<template>
<!--  <Spin fix :show="spinShow" >加载中...</Spin>-->
  <div class="info">
    <div class="title"><h3>{{ data.title }}</h3></div>
    <div class="outher">
      <div>作者: <span v-for="(item, index) in data.adminInfo" :key="index">{{ item.admin_name }}</span></div>
      <div>创建时间:{{ formatDate(data.created_at) }}</div>
      <div>所属分类: <span v-for="(item, inedx) in data.classification" :key="inedx">{{ item.name }}</span></div>
    </div>
    <div class="content">
      <div v-html="data.content" @click="showImage($event)"></div>
    </div>
    <ImagePreview v-model="preview" :preview-list="imageUrl" />
  </div>
</template>

<script>
import contentRequest from "../../request/apiRequest/contentRequest";
import moment from "moment/moment";
export default {
  name: "info",
  data() {
    return {
      data: [],
      id: null,
      spinShow: true,
      class: 0,
      preview: false,
      imageUrl: []
    }
  },
  methods: {
    formatDate(value) {
      const date = new Date(value);
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getInfo() {
      contentRequest.Info(this.id).then((res) => {
        if (res.status === 0) {
          this.data = res.data;
          window.document.title = res.data.title;
        } else {
          if (this.class === 0) {
            this.$router.push({ name: "Index" });
          } else {
            this.$router.push(`/content/list/${this.class}`);
          }
        }
      });
    },
    showImage(e) {
      if (e.target.tagName === "IMG") {
        this.imageUrl = [e.target.src];
        this.preview = true;
      }
    }
  },
  mounted() {
    this.class = this.$route.params.class;
    this.id = this.$route.params.id;
    this.getInfo();
  }
}
</script>

<style scoped>
.info {
  width: 100%;
}
.title {
  text-align: center;
}
.outher {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 10px;
}
.content {
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
}
</style>
